.Profile .content {
  padding: 0 20px;
}
.Profile .content .mainText {
  font-family: NanumGothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #2e2e2e;
  margin-bottom: 20px;
  margin-top: 20px;
}
.Profile .content .desc {
  font-family: Neue Montreal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #828282;
}
.Profile .content .illustration {
  margin-top: 20px;
}
.Profile form {
  margin-top: 30px;
  text-align: center;
  font-size: 13px;
}
.Profile form label {
  width: 100px;
  text-align: right;
  padding-right: 20px;
  display: inline-block;
}
.Profile form input {
  user-select: none;
  width: 150px;
  border: none;
  box-shadow: none;
  background: #fff;
  padding: 10px 15px;
}
.Profile form div {
  margin-bottom: 20px;
}
.Profile form .btn2 {
  padding: 10px 15px;
  background: #fff;
  border: none;
  box-shadow: none;
  border-radius: 15px;
}
.Profile .btn {
  font-size: 13px;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
  background: #fff;
  display: inline-block;
  border-radius: 25px;
  margin-top: 20px;
  min-width: 150px;
  user-select: none;
  cursor: pointer;
  box-shadow: 0px 18px 63px rgba(249,27,91,0.09), 0px 4.02054px 14.0719px rgba(249,27,91,0.054), 0px 1.19702px 4.18956px rgba(249,27,91,0.036);
  border-radius: 30px;
}
.Profile .btn svg,
.Profile .btn img {
  width: 18px;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 10px;
}
.Profile .btn.registerbtn {
  text-align: center;
}
.Profile .createBtn {
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-clip: padding-box /* !importanté */;
  border: solid 2px transparent /* !importanté */;
  border-radius: 40px;
  padding: 10px 20px;
  position: relative;
  display: inline-block;
  background: linear-gradient(180deg, rgba(255,83,121,0.7) 0%, #ff5379 100%);
  margin-top: 30px;
}
.Profile .createBtn:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0ff;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: linear-gradient(100.57deg, rgba(255,83,121,0.5), #ff5379);
}
.Profile .login {
  position: absolute;
  width: 209px;
  height: 15px;
  left: 83px;
  top: 617px;
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-top: 30px;
  color: #828282;
}
.Profile .login span {
  color: #ff5379;
}
/*# sourceMappingURL=src/views/CreateProfile.css.map */