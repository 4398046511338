.setting {
  color: #000;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  user-select: none;
}
.setting img {
  transform-origin: center center;
  width: 32px;
  height: 32px;
  opacity: 0;
  will-change: opacity transform;
}
.setting:hover {
  color: #fafafa;
}
/*# sourceMappingURL=src/components/SettingButton.css.map */